import "./card.css";

import React, { useRef } from "react";
import styled from "styled-components";
import useOnScreen from "./useOnScreen";

function Card(props) {
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  const isCheckedIn = props.location.isCheckedIn;
  const lastClickResults = props.location.lastClickResults;
  const checkInAttempts = props.location.checkInAttempts;

  function renderCheckInBadge(message, success, visible) {
    const checkedInLogo = (
      <svg
        width="100%"
        viewBox="0 0 1 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.508712 0.793419C0.463971 0.850056 0.428909 0.893348 0.428909 0.893348C0.428909 0.893348 0.227706 0.647361 0.178011 0.565945C0.148855 0.518184 0.130737 0.464188 0.130737 0.404861C0.130737 0.240296 0.264343 0.106689 0.428909 0.106689C0.593473 0.106689 0.72708 0.240296 0.72708 0.404861C0.72708 0.44712 0.718915 0.487218 0.70283 0.523707C0.590243 0.527381 0.499982 0.619924 0.499982 0.733382C0.499982 0.754245 0.503033 0.774404 0.508712 0.793419ZM0.42857 0.303141C0.483799 0.303141 0.528638 0.34798 0.528638 0.403209C0.528638 0.458438 0.483799 0.503277 0.42857 0.503277C0.373341 0.503277 0.328502 0.458438 0.328502 0.403209C0.328502 0.34798 0.373341 0.303141 0.42857 0.303141Z"
          fill="#DD7346"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.428511 0.256104C0.50972 0.256104 0.575649 0.322034 0.575649 0.403242C0.575649 0.484451 0.50972 0.550381 0.428511 0.550381C0.347302 0.550381 0.281372 0.484451 0.281372 0.403242C0.281372 0.322034 0.347302 0.256104 0.428511 0.256104ZM0.428511 0.303174C0.483739 0.303174 0.528578 0.348013 0.528578 0.403242C0.528578 0.458471 0.483739 0.50331 0.428511 0.50331C0.373282 0.50331 0.328443 0.458471 0.328443 0.403242C0.328443 0.348013 0.373282 0.303174 0.428511 0.303174Z"
          fill="#C25C31"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.709717 0.57373C0.797841 0.57373 0.869385 0.645275 0.869385 0.733399C0.869385 0.821523 0.797841 0.893067 0.709717 0.893067C0.621593 0.893067 0.550049 0.821523 0.550049 0.733399C0.550049 0.645275 0.621593 0.57373 0.709717 0.57373ZM0.656009 0.811492C0.663693 0.819393 0.674234 0.823866 0.685254 0.823907C0.696273 0.823947 0.706845 0.819552 0.714587 0.811709C0.751007 0.774828 0.810479 0.714601 0.828684 0.696165C0.831712 0.693097 0.831712 0.688163 0.828684 0.685095C0.818728 0.675014 0.797756 0.653778 0.787777 0.64367C0.784732 0.640588 0.779771 0.640544 0.776673 0.643571C0.755623 0.664127 0.682457 0.735576 0.682457 0.735576C0.682457 0.735576 0.654753 0.707208 0.642619 0.694784C0.641143 0.693273 0.639121 0.69241 0.637008 0.692386C0.634891 0.692366 0.632856 0.693189 0.631346 0.694672C0.621512 0.704343 0.601441 0.724079 0.591586 0.733771C0.58842 0.736883 0.588362 0.74197 0.591458 0.745149C0.603642 0.757672 0.633293 0.788146 0.656009 0.811492Z"
          fill="#46B59C"
        />
      </svg>
    );

    const notCheckedInLogo = (
      <svg
        width="100%"
        viewBox="0 0 1 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.50859 0.793419C0.463849 0.850056 0.428787 0.893348 0.428787 0.893348C0.428787 0.893348 0.227584 0.647361 0.177889 0.565945C0.148733 0.518184 0.130615 0.464188 0.130615 0.404861C0.130615 0.240296 0.264221 0.106689 0.428787 0.106689C0.593351 0.106689 0.726958 0.240296 0.726958 0.404861C0.726958 0.44712 0.718793 0.487218 0.702708 0.523707C0.590121 0.527381 0.49986 0.619924 0.49986 0.733382C0.49986 0.754245 0.502911 0.774404 0.50859 0.793419ZM0.428448 0.303141C0.483677 0.303141 0.528516 0.34798 0.528516 0.403209C0.528516 0.458438 0.483677 0.503277 0.428448 0.503277C0.373219 0.503277 0.32838 0.458438 0.32838 0.403209C0.32838 0.34798 0.373219 0.303141 0.428448 0.303141Z"
          fill="#DD7346"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.428633 0.256104C0.509842 0.256104 0.575771 0.322034 0.575771 0.403242C0.575771 0.484451 0.509842 0.550381 0.428633 0.550381C0.347424 0.550381 0.281494 0.484451 0.281494 0.403242C0.281494 0.322034 0.347424 0.256104 0.428633 0.256104ZM0.428633 0.303174C0.483861 0.303174 0.5287 0.348013 0.5287 0.403242C0.5287 0.458471 0.483861 0.50331 0.428633 0.50331C0.373404 0.50331 0.328565 0.458471 0.328565 0.403242C0.328565 0.348013 0.373404 0.303174 0.428633 0.303174Z"
          fill="#C25C31"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.709717 0.57373C0.797841 0.57373 0.869385 0.645275 0.869385 0.733399C0.869385 0.821523 0.797841 0.893067 0.709717 0.893067C0.621593 0.893067 0.550049 0.821523 0.550049 0.733399C0.550049 0.645275 0.621593 0.57373 0.709717 0.57373ZM0.709388 0.682068L0.674962 0.647642C0.666436 0.639111 0.652606 0.639111 0.644075 0.647642C0.637702 0.654015 0.630333 0.661387 0.62396 0.667757C0.61543 0.676287 0.61543 0.690117 0.62396 0.698644L0.659036 0.73372L0.624407 0.76924C0.615989 0.777875 0.616165 0.791705 0.6248 0.800124C0.631254 0.806416 0.638718 0.813693 0.645172 0.819982C0.653808 0.828404 0.667638 0.828228 0.676056 0.819589L0.710042 0.784729L0.744468 0.819155C0.752998 0.827686 0.766828 0.827686 0.775355 0.819155C0.781728 0.812782 0.789101 0.805414 0.795474 0.79904C0.804001 0.79051 0.804001 0.77668 0.795474 0.768153L0.760398 0.733077L0.795023 0.697557C0.803445 0.688922 0.803269 0.675092 0.794631 0.666673C0.78818 0.660381 0.780713 0.653104 0.774262 0.646815C0.765623 0.638394 0.751796 0.63857 0.743378 0.647208L0.709388 0.682068Z"
          fill="#FD4444"
        />
      </svg>
    );
    return (
      <Wrapper>
        <Content isVisible={isVisible}>
          <CardCheckInBadge>
            <CheckInText>{message}</CheckInText>
            {success ? checkedInLogo : notCheckedInLogo}
          </CardCheckInBadge>
        </Content>
      </Wrapper>
    );
  }

  /*function render(props) {
    return (
 
        
      <div className="Card">
        <div className="Header">
          <Headline>{props.location.title}</Headline>
          <Category>{props.location.category}</Category>
        </div>
        <div className="Content">
          <img src={props.location.image} alt={props.location.title} />
          <p>{props.location.description}</p>
          <hr className="solid" />
        </div>
        <div className="Footer">
        {/* Check In Badge & Check-In Button 
          <div ref={ref}>
            {isCheckedIn &&
              renderCheckInBadge(
                "Du hast diesen Ort eingecheckt!",
                isCheckedIn,
                isVisible
              )}
            {!isCheckedIn &&
              checkInAttempts > 0 &&
              renderCheckInBadge(
                `Du bist leider am falschen Ort. Du bist aktuell ${lastClickResults.distance.toFixed(
                  1
                )} ${lastClickResults.unit} entfernt`,
                props.location.isCheckedIn,
                isVisible
              )}
            {!isCheckedIn && checkInAttempts > 0 && (
              <Button onClick={() => props.handleClick({})}>
                Nochmal probieren
              </Button>
            )}
            {!isCheckedIn && checkInAttempts <= 0 && (
              <Button onClick={() => props.handleClick({})}>Einchecken</Button>
            )}
          </div>
        </div>
      </div>
    );

    
  }*/

  function renderColouredCard(props){
      return (
                <ColouredCard category = {props.location.category}>
          <CardHeader>
            <Headline>{props.location.title}</Headline>
            <Category  category = {props.location.category}>{props.location.category}</Category>
          </CardHeader>
          <CardContent>
            <CardImage
              src={props.location.image}
              alt={props.location.title}
              width="100%"
            />
            <CardText>{props.location.description}</CardText>
            <CardLine></CardLine>
          </CardContent>
          <CardFooter>
            <div ref={ref}>
              {isCheckedIn &&
                renderCheckInBadge(
                  "Du hast diesen Ort eingecheckt!",
                  isCheckedIn,
                  isVisible
                )}
              {!isCheckedIn &&
                checkInAttempts > 0 &&
                renderCheckInBadge(
                  `Du bist leider am falschen Ort. Du bist aktuell ${lastClickResults.distance.toFixed(
                    1
                  )} ${lastClickResults.unit} entfernt`,
                  props.location.isCheckedIn,
                  isVisible
                )}
              {!isCheckedIn && checkInAttempts > 0 && (
                <Button onClick={() => props.handleClick({})}>
                  Nochmal probieren
                </Button>
              )}
              {!isCheckedIn && checkInAttempts <= 0 && (
                <Button onClick={() => props.handleClick({})}>
                  Einchecken
                </Button>
              )}
            </div>
          </CardFooter>
        </ColouredCard>
      );
    }

  return renderColouredCard(props);
}

const CardCheckInBadge = styled.div`
  background: rgb(222, 91, 110);
  background: linear-gradient(
    90deg,
    rgba(222, 91, 110, 1) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 64%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 10px;
  margin: 10px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.7fr 0.5fr;
  gap: 0px 0px;
  align-items: center;

  box-shadow: 0 20px 30px -15px rgba(0, 0, 0, 0.15);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const ColouredCard = styled.div`

 background-color: rgba(50, 50, 50, 0.4);

  color: white;
  width: 90%;

  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  
  backdrop-filter: blur(30px);

  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-items: space-around;

  @media (max-width: 340px) {
    width: 300px;
  }

  @media(min-width: 900px){

    &:hover {
  transform: scale(1.05, 1.05);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
} }
`;

const Headline = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: white;
`;

const CardHeader = styled.div`
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 15px;
  border: 0px solid red;
`;

const CardContent = styled.div`
  flex: auto;
`;

const CardFooter = styled.div`
  width: 100%;
`;
const CardText = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  margin: 0px;
  padding: 15px;
  overflow: hidden;
  line-height: 1.55;
`;

const CheckInText = styled(CardText)`
  margin: 0;
`;

const CardImage = styled.img`
  width: 95%;
  height: 200px;
  object-fit: cover;
  margin: 0px;
  margin-top: 0px;
  border-radius: 10px;
  box-shadow: 0 20px 30px -15px rgba(0, 0, 0, 0.4);
`;

const CardLine = styled.hr`
  border: none;
  height: 20px;
  width: 100%;
  height: 50px;
  margin-top: 0;
  border: 0;
  box-shadow: 0 20px 30px -15px rgba(0, 0, 0, 0.15);
  /* 	box-shadow: 0 20px 20px -20px #333;
 */
  margin: -50px auto 10px;
`;

const Category = styled.div`
  font-size: 12px;
  color: white;

   background-color: ${(props) => 
 props.category === "Sightseeing" ? "rgba(174,208,123,0.6)"
 :props.category === "Nightlife" ? "rgba(1,158,212,0.7)"
 :props.category === "Campus" ? "rgba(238,131,98,0.8)"
 : 'green'};
 padding: 2px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 10px;
  margin-top: 1px;
  margin-bottom: 5px;
`;

const Wrapper = styled.div``;

const Button = styled.button`
  background: rgba(255, 255, 255, 0.5);
  width: 90%;
  color: black;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:hover {
    background: white;
    color: black;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    transform: translateY(-3px);
  }
`;

const Content = styled.div`
  transition: 3s cubic-bezier(0.075, 0.82, 0.165, 1);
	display: ${(props) => (props.isVisible ? "static" : "none")};
  animation: ${(props) =>
    props.isVisible && "scale 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)"};

  @keyframes scale {
   0% {
	  opacity: 0;
	  transform: translateY(40px)
  }
  100% {
	  opacity: 1;
	  transform: translateY(0px)
  }
`;

export default Card;
