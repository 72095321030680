import { useState } from "react";
import styled from "styled-components";
import ProgressBar from "./progress-bar.component";
//import useScrollBlock from "./useScrollBlock";

export const TopBar = (props) => {
  return (
    <div>
      <TopBarContainer>
        <TopBarLogo src={require("../images/logo-colearn-ccc.png")} />
      </TopBarContainer>
      <TopBarMenu categories={props.categories} />
    </div>
  );
};

const TopBarContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(255,255,255,1);
  text-align: left;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.15);
  z-index: 9;
`;

const TopBarLogo = styled.img`
  height: 45px;
  margin-left: 15px;
  z-index: 9999999;
`;



const TopBarMenu = (props) => {
  function renderMenuItem(item) {
    return (
      <MenuItem>
        <ProgressBar
          label={item.name}
          completed={(item.checkInCount / item.totalLocationCount) * 100}
          bottomBar={false}
        />
      </MenuItem>
    );
  }

  function renderAwardItem(item) {
    var isAchieved = false;
    if (item.checkInCount === item.totalLocationCount) {
      isAchieved = true;
    }
    const imageString = "images/awards/badge-" + item.name + ".png";
    const awardImage =  require("../" + imageString) 
    return (
      <AwardContainer>
        <AwardImage
          src={awardImage}
          alt="{item.name}"
          achieved = {isAchieved}
        ></AwardImage>
        <AwardLabel>{item.name}</AwardLabel>
      </AwardContainer>
    );
  }

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const resetGame = () => {
    console.log("Reset Game");
    localStorage.clear();
    window.location.reload();
  };

  //const [blockScroll, allowScroll] = useScrollBlock();

  return (
    <div>
      <MenuLabel onClick={handleClick}>
        <Icon clicked={click} />
      </MenuLabel>
      {/* Menü mit entsprechenden Prograss Bars  */}
      <MenuItemContainerSplash visible={click}>
        <Headline>Fortschritt</Headline>
        <AwardProgress>{props.categories.map((category) => renderMenuItem(category))}</AwardProgress>
        <Headline>Deine Auszeichnungen</Headline>
        <Awards>
          {props.categories.map((category) => renderAwardItem(category))}
        </Awards>
        <ResetButton onClick={resetGame}>Spiel zurücksetzen</ResetButton>
      </MenuItemContainerSplash>
    </div>
  );
};


const Headline = styled.div`
 color: rgba(220,220,220,1);
 font-size: 28px;
 font-weight: bold;
 padding: 30px;
`;

const AwardProgress = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-left:  10px;
 
  row-gap: 5px;
`;

const AwardLabel = styled(Headline) `
padding: 10px;
`;

const Awards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 25px;
  row-gap: 25px;

`;



const AwardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AwardImage = styled.img`
  width: 200px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
 
  
  filter: ${(props) => (props.achieved ? '' : 'grayscale(100%)  blur(3px)')};
`;

const ResetButton = styled.button`
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 15px 32px;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 25px;
  cursor: pointer;
  margin-bottom: 200px;
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px 0px;
  }
`;

const MenuItemContainerSplash = styled.ul`
  opacity: ${(props) => (props.visible ? "1.0" : "0")};
  background-color: ${(props) =>
    props.clicked ? "transparent" : "rgba(55,55,55,0.8)"};
  list-style-type: none;
  position: fixed;
  right: 0px;
  width: 100vw;
  height 100vh;
  margin: 0;
  padding: 0;
  border-radius: 0 0 5px 5px;
  transition: all 0.2s;
  top: ${(props) => (props.visible ? "50px" : "0px")};
  visible: ${(props) => (props.visible ? true : false)};
  webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  z-index: ${(props) => (props.visible ? 8 : -8)};
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.15);

  overflow: scroll;

  //Scroller
   ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

const MenuItem = styled.li`
  padding: 0px;
`;

const MenuLabel = styled.div`
  cursor: pointer;
  position: fixed;

  width: 25px;
  height: 25px;
  right: 20px;
  top: 12.5px;
  text-align: right;
  z-index: 9999999;
`;

const Icon = styled.div`
  position: fixed;
  background-color: ${(props) => (props.clicked ? "transparent" : "black")};
  width: 25px;
  height: 3px;
  top: 23px;
  right: 10px;
  border-radius: 10px;
  display: inline-block;
  transition: all 0.4s;

  &::before,
  &::after {
    content: "";
    background-color: black;
    width: 25px;
    height: 3px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }

  &::before {
    top: ${(props) => (props.clicked ? "0" : "-7px")};
    transform: ${(props) => (props.clicked ? "rotate(-45deg)" : "rotate(0)")};
  }
  &::after {
    top: ${(props) => (props.clicked ? "0" : "7px")};
    transform: ${(props) => (props.clicked ? "rotate(45deg)" : "rotate(0)")};
  }
`;
