 export function calculateDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = degreesToRadians(lat2 - lat1);
    var dLon = degreesToRadians(lon2 - lon1);
    if (dLat && dLon) {
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(lat1)) *
          Math.cos(degreesToRadians(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
    }
    return d; //<= graceDistance? "True" : "False";
  }

  function degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }