import styled from "styled-components";

const ProgressBar = (props) => {
  const { label, completed, bottomBar } = props;

  return (
    <div>
   <ConditionalWrapper 
        condition={bottomBar} 
        wrapper= {children => <BottomProgressBarContainer>{children}</BottomProgressBarContainer>} 
        wrapper2= {children => <ProgressBarContainer>{children}</ProgressBarContainer>}
   >

      <ProgressBarLabel>{label}</ProgressBarLabel>
      <ProgressBarBackground>
        <ProgressBarValue completed={completed}>
          <ValueLabel>{completed.toFixed(1)}%</ValueLabel>
        </ProgressBarValue>
      </ProgressBarBackground>
  
</ConditionalWrapper>

    </div>
  );
};

const ConditionalWrapper = ({ condition, wrapper,wrapper2, children }) => 
  condition ? wrapper(children) : wrapper2(children);

const ProgressBarContainer = styled.div`
  display: flex;
  color: rgba(200,200,200,1);
  font-weight: bold;
  text-align: center;
`;

const BottomProgressBarContainer = styled(ProgressBarContainer)`
    background-color: rgba(40,40,40,1);    
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 9999999;
    color: rgba(200,200,200,1);
    font-weight: bold;
    
`;


const ProgressBarBackground = styled.div`
  height: 35px;
  width: 100%;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.2);
  margin-right: 25px;
  margin-top:10px;
  margin-bottom: 10px;
`;

const ProgressBarValue = styled.div`
  height: inherit;
  width: ${(props) => props.completed}%;

  border-radius: inherit;
  background: linear-gradient(
    344deg,
    rgba(222, 91, 110, 1) 0%,
    rgba(71, 19, 162, 1) 100%
  );
  transition: width 1s ease-in-out;
`;

const ValueLabel = styled.div`
  height: inherit;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-left: 15px;
`;

const ProgressBarLabel = styled.div`
  height: inherit;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: left;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 25px;
  width: 150px;
`;

export default ProgressBar;
