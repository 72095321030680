import { useEffect, useState } from "react";
import staticdata from "../staticdata.json";

const useJsonData = (url) => {
    const [data, setData] = useState([]);
  
    useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const database = await response.json();
        setData(database);
      } catch (error) {
        console.log("Error", error)
      setData(staticdata.locations);
      }
    };
    fetchData();
  }, [url]);
    return data;
};

export default useJsonData;

