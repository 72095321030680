import React, { useState, useEffect } from "react";
import Card from "./Card";

//Functional Imports
import { calculateDistance } from "./calculateDistance";
import { distanceThreshold } from "./gameRules";

import data from "../staticdata.json";

import ProgressBar from "./progress-bar.component";
import styled from "styled-components";
//import { useGeolocated } from "react-geolocated";
import { TopBar } from "./TopBar";
import GameIntroView from "./GameIntroView";

const Game = () => {
  const [totalLocations, setTotalLocations] = useState(0);
  const [checkInCount, setCheckInCount] = useState(0);
  const [gameCards, setGameCards] = useState([]);
  // eslint-disable-next-line
  const [categories, setCategories] = useState([]);

  //Getting the geolocation.
  const [coords, setCoords] = useState([]);
  const [isGeolocationAvailable, setisGeoLocationAvailable] = useState(null);
  const [isGeolocationEnabled, setisGeolocationEnabled] = useState(null);
  //const [getPosition, setGetPosition] = useState(null)

  function initializeLocationData() {
    var tempCardArray;
    const dataFromLocalStorage = localStorage.getItem("game-cards");
    //If theres something in the local Storage, load this, else Rebuild

    if (dataFromLocalStorage) {
      console.log("Reading from local storage");
      tempCardArray = JSON.parse(dataFromLocalStorage);
      console.log("Builded Array", tempCardArray);
    } else {
      //Now the game cards initialization - load Json and add fields
      console.log("Initializing data for 1st run");
      tempCardArray = [];
      data.locations.forEach((item) => {
        let newItem = item;
        newItem.isCheckedIn = false;
        newItem.lastClickResults = null;
        newItem.checkInAttempts = 0;
        tempCardArray.push({ key: item.id, location: newItem });
      });
    }
    return tempCardArray;
  }
  function getCategories(data) {
    const categoryDataArray = [
      ...data
        .reduce((map, obj) => map.set(obj.location.category, obj), new Map())
        .values(),
    ];
    var tmpCategories = [];
    categoryDataArray.forEach((item) => {
      tmpCategories.push({ name: item.location.category, count: 0 });
    });
    return tmpCategories;
  }

  function prepareCategoryData(gameCardData) {
    var categories = getCategories(gameCardData);
    categories.forEach((category) => {
      var counter = 0;
      gameCardData.forEach((gameCardItem) => {
        if (gameCardItem.location.category === category.name) {
          counter++;
        }
      });
      category.count = counter;
    });
    return categories;
  }

  useEffect(() => {
    // This runs once because of [] as 2nd Parameter
    //ACHTUNG - HIER NUR AM ENDE DIE CONST SETTEN, NICHT DRAUF VERWEISEN!!!
    console.log("Initializing...");

    var gameCardArray = initializeLocationData();

    var categories = prepareCategoryData(gameCardArray);
    var categoryCheckData = getCheckInCount(gameCardArray, categories);

    setCategories(categoryCheckData);
    setGameCards(gameCardArray);
    setTotalLocations(gameCardArray.length);

    setCheckInCount(calculateCheckins(gameCardArray));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("Writing Data to local storage");
    localStorage.setItem("game-cards", JSON.stringify(gameCards));
  }, [gameCards]);

  function renderCard(location) {
    return (
      <Card location={location} handleClick={() => handleClick(location)} />
    );
  }
  // eslint-disable-next-line
  function renderProgressBar(item) {
    return (
      <ProgressBar
        label={item.name}
        completed={(item.checkInCount / item.totalLocationCount) * 100}
      />
    );
  }

  const [nStat, setStat] = useState(null);

  const getLocator = () => {
    if (!navigator.geolocation) {
      setStat("Geo not availabe");
      setisGeoLocationAvailable(false);
      console.log("No locatipon available");
    } else {
      setStat("Position wird abgefragt...");
      setisGeoLocationAvailable(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setisGeolocationEnabled(true);
          setStat("Location available");
          console.log("Locatipon available");
          setCoords(position.coords);
          console.log(position.coords);
        },
        () => {
          setStat("Unable to retreive Locatipon");
          console.log(nStat);
        }
      );
    }
  };
  function renderGame() {
    return (
      <div>
        {console.log("Category", categories)}
        <TopBar categories={categories} />
        <GameContainer>
          {/* Zeigt den unteren Progess Bar An */}
          <ProgressBar
            label="Gesamt"
            completed={(checkInCount / totalLocations) * 100}
            bottomBar="true"
          />

          <div className="Cards">
            <div className="CardGroup">
              {React.Children.toArray(
                gameCards.map(({ key, location, isCheckedIn }) =>
                  renderCard(location)
                )
              )}
            </div>
          </div>
        </GameContainer>
      </div>
    );
  }

  function getCheckInCount(locationData, categories) {
    var categoryData = [];
    categories.forEach((category) => {
      var counter = 0;
      locationData.forEach((item) => {
        if (
          item.location.isCheckedIn === true &&
          item.location.category === category.name
        ) {
          counter++;
        }
      });
      categoryData.push({
        name: category.name,
        checkInCount: counter,
        totalLocationCount: category.count,
      });
    });
    return categoryData;
  }

  function calculateCheckins(locationData) {
    console.log("Calculation CheckIns...");
    var checkinCount = 0;
    locationData.forEach((item) => {
      if (item.location.isCheckedIn === true) {
        checkinCount++;
      }
    });
    console.log("Total Check Ins", checkinCount);
    return checkinCount;
  }

  // Die Funktion enthält die mathematische prüfung, ob der Nutzer an einer bestimmten Location ist
  function checkLocation(targetLocation, userPosition, threshold) {
    var outputDistance = 0;
    var distanceUnit = "m";
    var checkedIn = false;

    var distance = calculateDistance(
      userPosition.latitude,
      userPosition.longitude,
      targetLocation.latitude,
      targetLocation.longitude
    );

    //Einheiten Konvertieren
    if (distance * 1000 >= 1000) {
      outputDistance = distance;
      distanceUnit = "km";
    } else {
      outputDistance = distance * 1000;
    }

    //Prüfen, ob check in Erlaubt ist
    if (distance * 1000 <= threshold) {
      checkedIn = true;
    } else {
      checkedIn = false;
    }

    return {
      checkedIn: checkedIn,
      distance: outputDistance,
      unit: distanceUnit,
    };
  }

  // Die Funktion setzt den Prüfungsprozess in Gang, ob ein Check In erlaubt ist oder nicht.
  function handleClick(location) {
    console.log("Try check-in @", location.title);
    //Actualize userlocation
    getLocator(); //getPosition();

    //Hier muss die Logik rein, d.h. die Prüfung, ob die Location stimmt usw.
    const gameCardUpdate = gameCards.slice();

    const locationIndex = gameCards.findIndex(
      (item) => item.key === location.id
    );
    const checkLocationResults = checkLocation(
      location,
      coords,
      distanceThreshold
    );

    console.log(
      `Check in was ${!checkLocationResults.checkedIn ? "not" : ""} succesful.`
    );

    gameCardUpdate[locationIndex].location.isCheckedIn =
      checkLocationResults.checkedIn;
    gameCardUpdate[locationIndex].location.lastClickResults =
      checkLocationResults;
    gameCardUpdate[locationIndex].location.checkInAttempts++;

    var categories = prepareCategoryData(gameCardUpdate);
    var categoryCheckData = getCheckInCount(gameCardUpdate, categories);
    setCategories(categoryCheckData);

    //Anzeige Updaten
    setCheckInCount(calculateCheckins(gameCardUpdate));

    setGameCards(gameCardUpdate);
  }

  //Setup Rendering
  if (isGeolocationAvailable && isGeolocationEnabled) {
    return renderGame();
  } else {
    //Falls keine Standortfreigabe verfügbar ist, wird der Nachfolgende Screen angezeigt

    return (
      /*
      <GameIntroScreen>
          <IntroHeader>
          <GameLogo src={require("../images/logoOnly.png")} alt="Co3 Learn Logo"/>
          Festival of digital Connections
          </IntroHeader>
           <h1>City run</h1>
           <SplashScreenText> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
           Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed.</SplashScreenText>
            <SplashScreenText> Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan
             et iusto odio dignissim qui blandit praesent luptatu.</SplashScreenText>
             <Divider/>
         <GameIntroCTA>Bitte erlaube die Abfrage deines Standortes zur Nutzung dieser App.</GameIntroCTA>
          <GeoLocationButton onClick={getLocator}>Standort freigeben</GeoLocationButton>
      </GameIntroScreen>*/
      <ScreenWrapper>
      <CenterWrapper>
        <GameIntroScreen>
          <GameIntroView />
          <GeoLocationButton onClick={getLocator}>
            Standort freigeben
          </GeoLocationButton>
        </GameIntroScreen>
        </CenterWrapper>
        <BottomNavigationBar>
          <div>
            <Locations>
              <Project>
                <LocationHeader>DAS VERBUNDPROJEKT</LocationHeader>
                <LocationDetail>
                  Das Projekt Co3Learn wird gefördert aus Mitteln der Stiftung
                  Innovation in der Hochschullehre.<br></br>
                  <br></br>
                  Projektlaufzeit:<br></br> 01.08.2021 – 31.07.2024 mit
                  bewilligten Fördermitteln von 4.155.930 Euro.
                </LocationDetail>
              </Project>
              <Location>
                <LocationHeader>
                  TECHNISCHE UNIVERSITÄT BRAUNSCHWEIG
                </LocationHeader>
                <LocationDetail>
                  {" "}
                  Universitätsplatz 2<br></br>38106<br></br>
                  Braunschweig<br></br>
                  <br></br>
                  <Link href="https://www.tu-braunschweig.de/projekthaus/hive/aktuelle-projekte/co3learn">
                    Website
                  </Link>
                </LocationDetail>
              </Location>
              <Location>
                <LocationHeader>
                  GOTTFRIED WILHELM LEIBNIZ UNIVERSITÄT HANNOVER
                </LocationHeader>
                <LocationDetail>
                  Welfengarten 1<br></br>
                  30167<br></br>
                  Hannover<br></br>
                  <br></br>
                  <Link href="https://www.zqs.uni-hannover.de/de/elsa/projekte/co3learn">
                    Website
                  </Link>
                </LocationDetail>
              </Location>
              <Location>
                <LocationHeader>
                  GEORG-AUGUST-UNIVERSITÄT GÖTTINGEN
                </LocationHeader>
                <LocationDetail>
                  Wilhelmsplatz 1 (Aula)<br></br>
                  37073<br></br>
                  Göttingen <br></br>
                  <br></br>
                  <Link href="https://www.uni-goettingen.de/de/655676.html">
                    Website
                  </Link>
                </LocationDetail>
              </Location>
            </Locations>
            <ImprintBanner>
              © Copyright - co3learn (2022)
              <Link href="https://www.co3learn.de/impressum/">Impressum</Link>
            </ImprintBanner>
          </div>
        </BottomNavigationBar>
      </ScreenWrapper>
    );
  }
};

const CenterWrapper = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 height: 100%;
 flex: 1 1 auto;
`;

const ScreenWrapper = styled.div`


  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-between;
`;

const Locations = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 40px;
  gap: 25px;

   @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Project = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  max-width: 300px;
`;

const Location = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  max-width: 300px;
`;

const LocationHeader = styled.h3`
  font-weight: bold;
  font-size: 15px;
  color: rgba(150, 150, 150, 1);
`;

const LocationDetail = styled.p`
  font-size: 13px;
  color: rgba(150, 150, 150, 1);
`;

const BottomNavigationBar = styled.div`
  background-color: rgba(40, 40, 40, 1);
  display: flex;
  flex-direction: column;
  width: 100vw;
  color: rgba(150,150,150,1);
  
  
`;

const Link = styled.a`
  
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  color: rgba(190,190,190,1);

   &:hover {

    color: white;
    transition: 0.25s ease-in-out;
  }
`;

const ImprintBanner = styled.div`
  background-color: rgba(40, 40, 40, 1);
  border-top: rgba(255, 255, 255, 0.1) solid 1px;
  display: flex;
  justify-content: space-between;

  z-index: 9999999;
  font-size: 14px;
  padding: 20px 40px;
`;

const GameIntroScreen = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.6);
  padding: 15px;
  border-radius: 25px;
  max-width: 700px;
  margin: 20px;
  display: flex;
  min-height: 50vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(30px);
  background-color: rgba(100, 100, 100, 0.3);

`;

const GeoLocationButton = styled.button`
  /* Green */
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 25px;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px 0px;
    transition: 0.15s ease-in-out;
  }
`;

const GameContainer = styled.div`
  width: 100%;
  padding-top: 75px;
`;

export default Game;
