import "./App.css";
import React  from "react";
import Game from "./components/Game";


function App() {
  return (
    <div className="App">
      <header className="App-header">       
        <Game/>
      </header>
    </div>
  );
}

export default App;
