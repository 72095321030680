import styled from "styled-components";

const ModalViewer = (props) => {
  return (
    <ModalViewBackground>
      <ModalViewContainer>
        <ModalContent>
        
        <img src={props.logo}  width="100%" alt="Co3 Learn Logo"/>
        {props.text}
        </ModalContent>
      </ModalViewContainer>
    </ModalViewBackground>
  );
};

const ModalViewContainer = styled.div`
  display: flex;

  color: white;
  text-align: center;

  background: linear-gradient(
    344deg,
    rgba(222, 91, 110, 1) 0%,
    rgba(71, 139, 162, 1) 100%
  );

  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
  opacity: 0.98;

  position: fixed;
  left: 50%;
  top: 50%;

  width: 90%;
  height: 90%;

  z-index: 9999999;
  border-radius: 20px;

  transform: translate(-50%, -50%);
`;

const ModalContent = styled.div`
  padding: 35px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

const ModalViewBackground = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vh;
     background: linear-gradient(
                344deg, 
                rgba(222,91,110,1) 0%, 
                rgba(71,139,162,1) 100%);
`;

export default ModalViewer;
