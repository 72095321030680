import styled from "styled-components";
const GameIntroView = () => {
  return (
    <Wrapper>
      <IntroHeader>
        <CompanyLogo
          src={require("../images/logoOnly.png")}
          alt="Co3 Learn Logo"
        />
        Festival of Digital Connections
      </IntroHeader>
      <GameLogo src={require("../images/logo_run.png")} alt="Co3 Learn Logo" />
      <SplashScreenText>
        {" "}
        Herzlich willkommen zu unserer SCAVENGER HUNT durch Göttingen!
      </SplashScreenText>
      <SplashScreenText>
        {" "}
        Wir haben für Euch Orte und Plätze in Göttingen ausgesucht, mit denen
        Ihr die Stadt ein bisschen besser kennenlernen könnt. Hier findet Ihr
        handverlesen unsere Lieblingsorte in der Stadt, die alle Studis in
        Göttingen erlebt haben sollten — ganz egal, ob sie hier selbst studieren
        oder nur zu Besuch da sind.{" "}
      </SplashScreenText>
      <SplashScreenText>
        Manche Orte werdet Ihr automatisch durch den Besuch des Festivals
        kennenlernen, anderes müsst Ihr gezielt entdecken. Der Weg lohnt sich
        aber in jedem Fall!
      </SplashScreenText>
      <SplashScreenText>
        In drei Kategorien könnt Ihr je einen Badge sammeln: Campus, Sightseeing
        & Nightlife. Dazu müsst Ihr die ausgewählten Orte besuchen und Euch dort
        einchecken!
      </SplashScreenText>
      <SplashScreenText>
        Wir wünschen Euch viel Spaß bei der Erkundung unserer Stadt!
      </SplashScreenText>
      <SplashScreenText>Euer Co3Learn-Team</SplashScreenText>
      <Divider />
      <GameIntroCTA>
        Bitte erlaube die Abfrage deines Standortes zur Nutzung dieser App. Manchmal ist das GPS etwas ungenau - es lohnt sich, den Check-In Button mehrfach zu drücken.
      </GameIntroCTA>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 14px;
  color: white;
`;

const Divider = styled.hr`
  border-top: 1px solid white;
  width: 200px;
`;

const GameIntroCTA = styled.p`
  line-height: 1.4;
  font-size: 17px;
  font-weight: bold;
`;

const IntroHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;
const CompanyLogo = styled.img`
  width: 75px;
`;

const GameLogo = styled.img`
  width: 80%;
  max-width: 900px;
  padding: 10px;
`;

const SplashScreenText = styled.p`
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
  white-space: pre-line;
`;

export default GameIntroView;
